import { graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";
import React from "react";
import { Helmet } from "react-helmet";

import { Header } from "../components/header";
import { Tag } from "../components/post/tag";
import Footer from "../components/footer";
import { Theme, ThemeProviderProps } from "../theme";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: ${(props: ThemeProviderProps) => props.theme.dimensions.maxWidth};
  width: 1000px;
  margin: 0 auto;
`;

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Theme>
    <Wrapper>
      <Helmet title={title} />
      <Header small={true}>Tags</Header>
      {group.map(tag => (
        <Tag key={tag.fieldValue} name={kebabCase(tag.fieldValue)} />
      ))}
      <Footer />
    </Wrapper>
  </Theme>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
